import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../components/Home";
import FamilleProduits from "../components/familleProduits";
import Calendar from "../components/Calendar";
import Authentification from "../components/authentification";
import Index from "../components/index";
import FormeProduits from "../components/formeProduits";
import OrigineProduits from "../components/origineProduits";
import CategorieProduits from "../components/categorieProduits";
import Produits from "../components/produits";
import RegionNaturelles from "../components/regionNaturelles";
import Region from "../components/region";
import Prefecture from "../components/prefecture";
import Commune from "../components/commune";
import Collecteur from "../components/collecteur";
import Magasin from "../components/magasin";
import Marche from "../components/marche";
import Debarcadere from "../components/debarcadere";
import Unite from "../components/unite";
import Grossiste from "../components/grossiste";
import { Navigate } from "react-router-dom";
import PrivateRoute from "../components/privateRoute";
const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Authentification />} />

      <Route path="/app" element={<Index />} />
      
      <Route
        path="/home"
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
      />
      <Route
        path="famille"
        element={
          <PrivateRoute>
            <FamilleProduits />
          </PrivateRoute>
        }
      />
      <Route
        path="forme"
        element={
          <PrivateRoute>
            <FormeProduits />
          </PrivateRoute>
        }
      />
      <Route
        path="origine"
        element={
          <PrivateRoute>
            <OrigineProduits />
          </PrivateRoute>
        }
      />
      <Route
        path="categorie"
        element={
          <PrivateRoute>
            <CategorieProduits />
          </PrivateRoute>
        }
      />
      <Route
        path="produit"
        element={
          <PrivateRoute>
            <Produits />
          </PrivateRoute>
        }
      />
      <Route
        path="regionNaturelles"
        element={
          <PrivateRoute>
            <RegionNaturelles />
          </PrivateRoute>
        }
      />
      <Route
        path="region"
        element={
          <PrivateRoute>
            <Region />
          </PrivateRoute>
        }
      />
      <Route
        path="prefecture"
        element={
          <PrivateRoute>
            <Prefecture />
          </PrivateRoute>
        }
      />
      <Route
        path="commune"
        element={
          <PrivateRoute>
            <Commune />
          </PrivateRoute>
        }
      />
      <Route
        path="collecteur"
        element={
          <PrivateRoute>
            <Collecteur />
          </PrivateRoute>
        }
      />
      <Route
        path="magasin"
        element={
          <PrivateRoute>
            <Magasin />
          </PrivateRoute>
        }
      />
      <Route
        path="marche"
        element={
          <PrivateRoute>
            <Marche />
          </PrivateRoute>
        }
      />
      <Route
        path="debarcadere"
        element={
          <PrivateRoute>
            <Debarcadere />
          </PrivateRoute>
        }
      />
      <Route
        path="unite"
        element={
          <PrivateRoute>
            <Unite />
          </PrivateRoute>
        }
      />
      <Route
        path="grossiste"
        element={
          <PrivateRoute>
            <Grossiste />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default AppRoutes;

import Index from "./index";
import { useState, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import "ldrs/ring";
import Alert from '@mui/material/Alert';
import { hourglass } from "ldrs";
import { Modal, Button } from "react-bootstrap";
function FamilleProduits() {
  const [loading, setLoading] = useState(false);
  const [famillesProduits, setFamillesProduits] = useState([]);
  const [codeFamilleProduit, setCodeFamilleProduit] = useState("");
  const [nomFamilleProduit, setNomFamilleProduit] = useState("");
  const [familleId, setFamilleId] = useState(null); // Utilisé pour l'ID de la famille
  const [affichageEcran, setAffichageEcran] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedFamille, setSelectedFamille] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(""); 
  const [successMessage, setSuccessMessage] = useState(""); // Message de succès
  const [errorMessage, setErrorMessage] = useState(""); // Pour la famille à modifier

  // Charger les données existantes
  useEffect(() => {
    fetch("http://185.98.136.230:8000/api/v1/all-famille-produits/")
      .then((response) => response.json())
      .then((data) => setFamillesProduits(data))
      .catch((error) =>
        console.error("Erreur lors du chargement des données:", error)
      );
  }, []);

  // Simuler le chargement
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  // Ouvrir et fermer le modal
  const handleShow = (famille = null) => {
    if (famille) {
      setSelectedFamille(famille); // Remplir les champs pour modification
      setFamilleId(famille.id_famille_produit); // Capturer l'ID pour la modification
      setCodeFamilleProduit(famille.code_famille_produit);
      setNomFamilleProduit(famille.nom_famille_produit);
      setAffichageEcran(famille.affichage_ecran === 1);
    } else {
      setSelectedFamille(null); // Pour un nouvel ajout
      setFamilleId(null); // Aucun ID pour un nouvel ajout
      setCodeFamilleProduit("");
      setNomFamilleProduit("");
      setAffichageEcran(false);
    }
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  // Gérer le changement des inputs
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "codeFamilleProduit") {
      setCodeFamilleProduit(value);
    } else if (name === "nomFamilleProduit") {
      setNomFamilleProduit(value);
    }
  };

  // Gérer le changement du checkbox
  const handleCheckboxChange = () => {
    setAffichageEcran(!affichageEcran);
  };

  // Fonction pour ajouter ou modifier une famille de produit
  const soumettreFamilleProduit = () => {
    const familleData = {
      id_famille_produit: familleId, // Utiliser l'ID ici pour la modification
      code_famille_produit: codeFamilleProduit,
      nom_famille_produit: nomFamilleProduit,
      affichage_ecran: affichageEcran ? 1 : 0, // Si le checkbox est coché, mettre 1, sinon 0
      id_personnel: "1", // Remplacez par la valeur appropriée
      etat: "actif", // Un état par défaut
      date_enregistrement: selectedFamille
        ? selectedFamille.date_enregistrement
        : new Date().toISOString().split("T")[0],
      modifier_le: new Date().toISOString().split("T")[0],
      modifier_par: "admin", // Remplacez par la personne qui modifie
    };

    const url = selectedFamille
      ? `http://185.98.136.230:8000/api/v1/famille-produit/update/${familleId}/` // Utiliser l'ID ici
      : "http://185.98.136.230:8000/api/v1/famille-produit/create/";

    const method = selectedFamille ? "PUT" : "POST";

    fetch(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(familleData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (selectedFamille) {
          // Mise à jour de la famille dans la liste
          setFamillesProduits(
            famillesProduits.map((f) =>
              f.id_famille_produit === data.id_famille_produit ? data : f
            )
          );
        } else {
          // Ajouter une nouvelle famille à la liste
          setFamillesProduits([...famillesProduits, data]);
        }
        setSuccessMessage(
          selectedFamille
            ? "Modification réussie !"
            : "Ajout réussi !"
        );
        setErrorMessage("");
        setTimeout(() => {
          setSuccessMessage("");
        }, 3000);

        // Réinitialiser le formulaire et fermer le modal
        setFamilleId(null); // Réinitialiser l'ID après la soumission
        setCodeFamilleProduit("");
        setNomFamilleProduit("");
        setAffichageEcran(false);
        setShowModal(false);
      })
      .catch((error) => {
        // Afficher le message d'erreur
        setErrorMessage("Échec de l'enregistrement.");
        setSuccessMessage("");
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
        console.error("Erreur lors de l'ajout ou de la modification:", error);
      });
  };
  const supprimerFamilleProduit = (id) => {
    fetch(`http://185.98.136.230:8000/api/v1/famille-produit/delete/${id}/`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          // Supprimer la famille localement après succès
          setFamillesProduits(
            famillesProduits.filter(
              (famille) => famille.id_famille_produit !== id
            )
          );
          setDeleteMessage("Suppression réussie !");
          setTimeout(() => {
            setDeleteMessage(""); // Masquer le message après 3 secondes
          }, 3000);
        } else {
          console.error(
            "Erreur lors de la suppression de la région naturelle:",
            response
          );
        }
      })
      .catch((error) => {
        console.error(
          "Erreur lors de la suppression de la région naturelle:",
          error
        );
      });
  };

  // Gérer le clic sur "Supprimer"
  const handleDeleteClick = (id) => {
    if (
      window.confirm(
        "Êtes-vous sûr de vouloir supprimer cette famille de produits ?"
      )
    ) {
      supprimerFamilleProduit(id);
    }
  };
  // Ajouter ces états pour la pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Nombre d'éléments par page

  // Calculer les indices des éléments à afficher
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = famillesProduits.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Fonction pour changer de page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Nombre total de pages
  const totalPages = Math.ceil(famillesProduits.length / itemsPerPage);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // occupe toute la hauteur de la page
            backgroundImage: "assets/img/icons/logo-simro.png",
          }}
        >
          <l-hourglass
            color={"#00a600"}
            loading={loading}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          ></l-hourglass>
        </div>
      ) : (
        <>
          <Index />
          <div className="content">
            <h3
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
                color: "gold",
              }}
            >
              Liste des familles de produits
            </h3>
            {successMessage && (
              <Alert severity="success" sx={{ mb: 2 }}>
                {successMessage}
              </Alert>
            )}
            {errorMessage && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {errorMessage}
              </Alert>
            )}
                        {deleteMessage && (
              <Alert severity="info" sx={{ mb: 2 }}>
                {deleteMessage}
              </Alert>
            )}
            <div
              id="tableExample3"
              data-list='{"valueNames":["name","email","age"],"page":5,"pagination":true}'
              className="table-responsive"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div class="search-box mb-3">
                  <form className="position-relative">
                    <input
                      className="form-control search-input search form-control-sm"
                      type="search"
                      placeholder="Search"
                      aria-label="Search"
                    />
                    <svg
                      className="svg-inline--fa fa-magnifying-glass search-box-icon"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="magnifying-glass"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      data-fa-i2svg
                    >
                      <path
                        fill="currentColor"
                        d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                      />
                    </svg>
                    {/* <span class="fas fa-search search-box-icon"></span> Font Awesome fontawesome.com */}
                  </form>
                </div>
                <div>
                  <button
                    id="addBtn"
                    className="btn btn-primary"
                    onClick={() => setShowModal(true)}
                    style={{
                      backgroundColor: "#00a600",
                    }}
                  >
                    <svg
                      className="svg-inline--fa fa-plus me-2"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="plus"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      data-fa-i2svg
                    >
                      <path
                        fill="currentColor"
                        d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                      ></path>
                    </svg>
                    Nouvelle Famille
                  </button>
                </div>
              </div>
              <div
                className="table-responsive"
                style={{
                  backgroundColor : "#f8f9fa",
                  border : "1px solid #dee2e6",
                  boxShadow: "0 4px 8px green",
                  borderRadius: "5px",
                  padding : "20px",
                }}
              >
                <table
                  className="table table-striped table-sm fs-9 mb-0"
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                    // borderCollapse: "separate",
                    borderSpacing: "0 10px",
                    borderCollapse: "collapse", // Ajout d'espacement entre les lignes
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        className="sort"
                        style={{
                          backgroundColor: "#fc8e38",
                          color: "white",
                          fontWeight: "700",
                          fontSize: "18px",
                          textAlign: "center",
                          borderBottom: "2px solid #dee2e6", // Ligne de séparation
                        }}
                      >
                        Code Famille Produit
                      </th>
                      <th
                        className="sort"
                        style={{
                          backgroundColor: "#fc8e38",
                          color: "white",
                          fontWeight: "700",
                          fontSize: "18px",
                          textAlign: "center",
                          borderBottom: "2px solid #dee2e6",
                        }}
                      >
                        Nom Famille Produit
                      </th>
                      <th
                        className="sort"
                        style={{
                          backgroundColor: "#fc8e38",
                          color: "white",
                          fontWeight: "700",
                          fontSize: "18px",
                          textAlign: "center",
                          borderBottom: "2px solid #dee2e6",
                        }}
                      >
                        ID Personnel
                      </th>
                      <th
                        className="sort"
                        style={{
                          backgroundColor: "#fc8e38",
                          color: "white",
                          fontWeight: "700",
                          fontSize: "18px",
                          textAlign: "center",
                          borderBottom: "2px solid #dee2e6",
                        }}
                      >
                        ID Famille Produit
                      </th>
                      <th
                        className="sort text-end align-middle pe-0 border-top"
                        scope="col"
                        style={{
                          backgroundColor: "red",
                          color: "white",
                          fontWeight: "700",
                          fontSize: "18px",
                          textAlign: "center",
                          borderBottom: "2px solid #dee2e6",
                          padding: "10px 20px",
                        }}
                      >
                        ACTION
                      </th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {currentItems.length > 0 ? (
                      currentItems.map((famille, index) => (
                        <tr
                          key={index}
                          style={{ borderBottom: "1px solid #dee2e6" }}
                        >
                          <td
                            className="align-middle ps-3"
                            style={{
                              textAlign: "center",
                              border: "1px solid #dee2e6", // Ajout de bordures autour des cellules
                              padding: "10px",
                            }}
                          >
                            {famille.code_famille_produit}
                          </td>
                          <td
                            className="align-middle ps-3"
                            style={{
                              textAlign: "center",
                              border: "1px solid #dee2e6",
                              padding: "10px",
                            }}
                          >
                            {famille.nom_famille_produit}
                          </td>
                          <td
                            className="align-middle"
                            style={{
                              textAlign: "center",
                              border: "1px solid #dee2e6",
                              padding: "10px",
                            }}
                          >
                            {famille.id_personnel || "N/A"}
                          </td>
                          <td
                            className="align-middle"
                            style={{
                              textAlign: "center",
                              border: "1px solid #dee2e6",
                              padding: "10px",
                            }}
                          >
                            {famille.id_famille_produit}
                          </td>
                          <td className="align-middle white-space-nowrap text-end pe-0">
                            <div className="btn-reveal-trigger position-static">
                              <button
                                className="btn btn-sm dropdown-toggle dropdown-caret-none transition-none btn-reveal fs-10"
                                type="button"
                                data-bs-toggle="dropdown"
                                data-boundary="window"
                                aria-haspopup="true"
                                aria-expanded="false"
                                data-bs-reference="parent"
                              >
                                <svg
                                  className="svg-inline--fa fa-ellipsis fs-10"
                                  aria-hidden="true"
                                  focusable="false"
                                  data-prefix="fas"
                                  data-icon="ellipsis"
                                  role="img"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 448 512"
                                >
                                  <path
                                    fill="currentColor"
                                    d="M8 256a56 56 0 1 1 112 0A56 56 0 1 1 8 256zm160 0a56 56 0 1 1 112 0 56 56 0 1 1 -112 0zm216-56a56 56 0 1 1 0 112 56 56 0 1 1 0-112z"
                                  />
                                </svg>
                              </button>
                              <div className="dropdown-menu dropdown-menu-end py-2">
                                <a
                                  className="dropdown-item"
                                  onClick={() => handleShow(famille)}
                                >
                                  Modifier
                                </a>
                                <div className="dropdown-divider" />
                                <a
                                  className="dropdown-item text-danger"
                                  onClick={() =>
                                    handleDeleteClick(
                                      famille.id_famille_produit
                                    )
                                  }
                                >
                                  Supprimer
                                </a>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan="5"
                          className="text-center"
                          style={{ padding: "10px" }}
                        >
                          Aucune forme trouvée.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div className="d-flex justify-content-between mt-3">
                <ul className="pagination">
                  {Array.from({ length: totalPages }, (_, index) => (
                    <li key={index + 1} className="page-item">
                      <button
                        onClick={() => paginate(index + 1)}
                        className={`page-link ${
                          currentPage === index + 1 ? "active" : ""
                        }`}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {/* Modal d'ajout ou de modification */}
            {showModal && (
              <div
                className="modal fade show d-block"
                tabIndex="-1"
                role="dialog"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content border border-translucent">
                    <form
                      id="addFamilyForm"
                      autoComplete="off"
                      onSubmit={(e) => {
                        e.preventDefault();
                        soumettreFamilleProduit();
                      }}
                    >
                      <div className="modal-header px-card border-0">
                        <div className="w-100 d-flex justify-content-between align-items-start">
                          <div>
                            <h5 className="mb-0 lh-sm text-body-highlight">
                              {selectedFamille
                                ? "Modifier Famille de Produit"
                                : "Ajouter une Famille de Produits"}
                            </h5>
                          </div>
                          <button
                            type="button"
                            className="btn p-1 fs-10 text-body"
                            onClick={handleClose}
                          >
                            Annuler
                          </button>
                        </div>
                      </div>

                      <div className="modal-body p-card py-0">
                        {/* Code Famille Produit */}
                        <div className="form-floating mb-3">
                          <input
                            className="form-control"
                            id="codeFamilleProduit"
                            type="text"
                            name="codeFamilleProduit"
                            value={codeFamilleProduit}
                            onChange={handleInputChange}
                            required
                            placeholder="Code Famille Produit"
                          />
                          <label htmlFor="codeFamilleProduit">
                            Code Famille Produit
                          </label>
                        </div>

                        {/* Nom Famille Produit */}
                        <div className="form-floating mb-3">
                          <input
                            className="form-control"
                            id="nomFamilleProduit"
                            type="text"
                            name="nomFamilleProduit"
                            value={nomFamilleProduit}
                            onChange={handleInputChange}
                            required
                            placeholder="Nom Famille Produit"
                          />
                          <label htmlFor="nomFamilleProduit">
                            Nom Famille Produit
                          </label>
                        </div>

                        {/* Affichage Écran */}
                        <div className="form-check mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="affichageEcran"
                            name="affichageEcran"
                            checked={affichageEcran}
                            onChange={handleCheckboxChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="affichageEcran"
                          >
                            Affichage Écran
                          </label>
                        </div>
                      </div>

                      <div className="modal-footer d-flex justify-content-between align-items-center border-0">
                        <button
                          className="btn btn-primary px-4"
                          type="submit"
                          style={{
                            backgroundColor: "#00a600",
                          }}
                        >
                          {selectedFamille
                            ? "Modifier Famille"
                            : "Ajouter Famille"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default FamilleProduits;
